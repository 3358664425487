@import '~antd/dist/antd.css';

.main-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    bottom: 1px;
    position: absolute;
    background-color: #6a8faf;
}

.header {

    background-color: #3c83ab;
    height: 30px;

}

.footer {
    background-color: #2a4853;
}


.sider {
    background-color:#6a8faf;
    display: flex;
    flex: 1;
    flex-direction: column;

}


.select-series {
    text-align: left;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    float: left;
    background-color: #3c83ab;
}

.select-series>.item {
    display: flex;
    flex-direction: row;
    flex: 1;
    float: left;
    width: 200px;
}

.peripheral-view {
    width: 88%;
    height: 90%;
    /*display: table;*/
    position: absolute;
    text-align: left;
    overflow-y: auto;
}

.config-area {
    display: table-cell;
    height: 90%;
    width: 50%;
    background: orange;
    overflow-y: auto;
}
.fl-view {
    display: table-cell;
    height: 90%;
    background: #45ae4d;
}

.form-item {
    margin-bottom: 2px;
}

.parameter-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
}

.parameter-name {
    display: inline;
    width: 100px;
    text-align: right;
    vertical-align: center;
    height: 30px;
    padding-top: 5px;
    margin-right: 10px;
}

.parameter-input {
    display: inline;
    width: 200px;
    height: 30px;

}


.parameter-li {
    list-style-type: none;
}

p {
    white-space: pre;
}